import { useEffect, useState } from "react";
import axios from "axios";
import { MdVerified } from "react-icons/md";
import { FaCircleExclamation } from "react-icons/fa6";

interface passportDetails {
  verified: string;
  upstreamName: string;
}

const PassportVerification = () => {
  const [data, setData] = useState<passportDetails | null>();
  // const [data, setData] = useState([] as any);
  const [passportNumber, setpassportNumber] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [dob, setDob] = useState<string>("");
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [submittedPassportNumber, setSubmittedPassportNumber] =
    useState<string>("");
  const [submittedName, setSubmittedName] = useState<string>("");
  const [submittedDob, setSubmittedDob] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (submittedPassportNumber === "") return;

      try {
        const requestData = {
          fileNumber: submittedPassportNumber,
          name: name,
          dob: dob,
        };

        const config = {
          method: "POST",
          maxBodyLength: Infinity,
          url: "https://signzy-node-proxy.onrender.com/api/v1/fetch/passportVerification",
          headers: {
            "x-api-key": process.env.REACT_APP_PROXY_API_KEY,
            "Content-Type": "application/json",
          },
          data: requestData,
        };

        const response = await axios.request(config);

        setData(response.data.result);
        setIsVerified(false);
      } catch (error: any) {
        setError(error.message);
        setIsVerified(false);
      } finally {
        setIsVerified(false);
      }
    };

    fetchData();
  }, [submittedPassportNumber, submittedDob, submittedName]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isVerified) return;
    setError(null)
    setData(null);
    setIsVerified(true);
    setSubmittedPassportNumber(passportNumber);
    setSubmittedName(name);
    setSubmittedDob(dob);
  };

  const handlePassportNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setpassportNumber(e.target.value);
    setIsVerified(false);
  };

  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setIsVerified(false);
  };

  const handleDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDob(e.target.value);
    setIsVerified(false);
  };

  return (
    <div className="py-8 px-10">
      <h1 className="text-3xl font-bold">Verify Your Passport</h1>

      <div className="flex items-center">
        <form className="py-2 px-20 " onSubmit={handleSubmit}>
          <div className="border-2 border-puprle-200 shadow-sm rounded-md py-4 px-8 mt-6 w-[400px] flex flex-col justify-center">
            <label className="text-md  text-gray-500">Passport Number :</label>{" "}
            <input
              required
              type="text"
              placeholder="Enter your Passport Number"
              value={passportNumber}
              onChange={handlePassportNumber}
              className="bg-transparent outline-none mb-2 py-1 px-4 rounded sm border-2 border-purple-200"
            />
            <label className="text-md  text-gray-500">Name :</label>{" "}
            <input
              required
              type="text"
              placeholder="Enter your Name"
              value={name}
              onChange={handleName}
              className="bg-transparent outline-none mb-2 py-1 px-4 rounded sm border-2 border-purple-200"
            />
            <label className="text-md  text-gray-500">Date Of Birth :</label>{" "}
            <input
              required
              type="text"
              id="dateInput"
              value={dob}
              onChange={handleDate}
              pattern="(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[0-2])/\d{4}"
              title="Please enter the date in dd/mm/yyyy format"
              placeholder="dd/mm/yyyy"
              className="bg-transparent outline-none mb-2 py-1 px-4 rounded sm border-2 border-purple-200"
            />
            <button
              className="bg-purple-600 py-1 px-4 text-white  rounded-md"
              disabled={isVerified ? true : false}
            >
              Verify
            </button>
          </div>
        </form>

        <div>
          {isVerified && (
            <span className="mx-20 text-gray-600">Verifying Data...</span>
          )}

          {!isVerified && data?.verified === "true" ? (
            <div className=" mx-8 bg-green-200 w-[500px] px-4 py-3 rounded-md text-green-600 flex items-center gap-x-2">
              <span className="text-xl">
                <MdVerified />
              </span>
              <span>
                Passport is Verified 
              </span>
            </div>
          ) : !isVerified &&
            submittedPassportNumber &&
            data?.verified === "false" ? (
            <div className=" mx-8 bg-red-200 w-[500px] px-4 py-3 rounded-md text-red-600 flex items-center gap-x-2">
              <span className="text-xl">
                <FaCircleExclamation />
              </span>
              <span>Passport is not Verified</span>
            </div>
          ) : error ? (
            <div className=" mx-8 bg-red-200 w-[500px] px-4 py-3 rounded-md text-red-600 flex items-center gap-x-2">
              <span className="text-xl">
                <FaCircleExclamation />
              </span>
              <span>Can't Verify you at the moment, or contact support</span>
            </div>
          ) : null}

          {data !== null && !isVerified && data?.verified === "true" && (
            <div className="mx-8 p-4 flex flex-col">
              <span className="py-1">
                Verified :{" "}
                <span className="text-gray-500">{data?.verified}</span>{" "}
              </span>
              <span className="py-1">
                Upstream Name :{" "}
                <span className="text-gray-500">{data?.upstreamName}</span>{" "}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PassportVerification;
