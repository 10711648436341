import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface propsFillingStatus{
    gstType: string;
    gstStatus: string;
    filingYear: string;
    dateOfFiling: string;
    monthOfFiling: string;
    methodOfFilling: string;
  }

  interface TableProps {
    fillingStatus:propsFillingStatus[],
  }



export default function BasicTable({fillingStatus} : TableProps) {
    
  return (
    <TableContainer component={Paper} className='my-4 max-h-[300px] relative scrollbar-hide'>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" className='relative'>
        <TableHead className='z-20'>
          <TableRow >
            <TableCell>GST Type</TableCell>
            <TableCell align="center" >GST Status</TableCell>
            <TableCell align="center" >Filling Year</TableCell>
            <TableCell align="center" className="font-bold">Date of Filling</TableCell>
            <TableCell align="center">Month of Filling</TableCell>
            <TableCell align="center">Method of Filling</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className=' overflow-y-scroll scrollbar-hide'>
          {fillingStatus.map((rec , index) => (
            <TableRow
              key={index+1}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {rec?.gstType}
              </TableCell>
              <TableCell align="center">{rec?.gstStatus}</TableCell>
              <TableCell align="center">{rec.filingYear}</TableCell>
              <TableCell align="center">{rec.dateOfFiling}</TableCell>
              <TableCell align="center">{rec.monthOfFiling}</TableCell>
              <TableCell align="center">{rec.methodOfFilling}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
