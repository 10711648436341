import React from "react";
import veliboLogo from "../assets/veliboLogo.png";
import { FaArrowUp } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="bg-[#1E1E1E] p-10 sm:px-10 sm:py-8 max-h-full max-w-full">
      <div className=" flex justify-evenly flex-col sm:flex-row">
        <div className="flex gap-x-3">
          <img
            src={veliboLogo}
            alt="Logo"
            className="w-[30px] h-[30px] rounded-md"
          />
          <span className="text-white text-xl font-['Plaster']">Velibo</span>
        </div>
        <div className=" flex justify-evenly flex-col w-full mt-6 sm:flex-row sm:mt-0 ">
          <div>
            <span className="text-white text-xl ">Platform</span>
            <ul className="text-[#9BA4B4] mt-2 flex flex-col gap-y-2 mb-4 ">
              <li>Platform Features</li>
              <li>why Velibo?</li>
              <li>Integrations</li>
            </ul>
          </div>
          <div>
            <span className="text-white text-xl">Solutions</span>
            <ul className="text-[#9BA4B4] mt-2 flex flex-col gap-y-2 mb-4">
              <li>For Engineers</li>
              <li>For FinOps</li>
              <li>For Finance</li>
              <li>For Partners</li>
            </ul>
          </div>
          <div>
            <span className="text-white text-xl">Pricing</span>
            <ul className="text-[#9BA4B4] mt-2 flex flex-col gap-y-2 mb-4">
              <li>Pricing</li>
            </ul>
          </div>
          <div>
            <span className="text-white text-xl">Company</span>
            <ul className="text-[#9BA4B4] mt-2 flex flex-col gap-y-2 mb-4 ">
              <li>About</li>
              <li>Carrers</li>
              <li>Contact</li>
              <li>Blogs</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-20 flex sm:flex-row flex-col justify-evenly gap-4 relative text-white">
        <span>©2024 Velibo.com All rights reserved</span>
        <Link to="/tos">Terms Of Service</Link>
        <Link to={"/privacyPolicy"}>
          <span>Privacy Policy</span>
        </Link>
        <button
          className="bg-blue-500 p-2 rounded-full text-2xl absolute right-2 z-10 bottom-0"
          onClick={handleScrollToTop}
        >
          <FaArrowUp />
        </button>
      </div>
    </div>
  );
};

export default Footer;
