import "./App.css";
import { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Intercom from "@intercom/messenger-js-sdk";

import LandingPage from "./pages/landingPage";
import LoginPage from "./pages/loginPage";
import SignupPage from "./pages/signupPage";
import Dashboard from "./pages/Dashboard/Dashboard";
import PanVerification from "./pages/Dashboard/PanVerification";
import AadharVerification from "./pages/Dashboard/AadharVerification";
import GstVerification from "./pages/Dashboard/GstVerification";
import PassportVerification from "./pages/Dashboard/PassportVerification";
import { useAuth } from "./components/context/authContext";
import PrivacyPolicyPage from "./pages/privacyPolicyPage";
import TermsOfService from "./pages/TermsOfService";
import CustomerComponent from "./pages/Dashboard/customers/customer";
import CustomerCard from "./pages/Dashboard/customers/customerCard";

function IntercomInitializer() {
  useEffect(() => {
    Intercom({
      app_id: "i08t8mxn",
    });
  }, []);

  return null;
}

function App() {
  const { userLoggedIn } = useAuth();

  const appRoutes = createBrowserRouter([
    {
      path: "/",
      element: userLoggedIn ? <Dashboard /> : <LandingPage />,
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/signup",
      element: <SignupPage />,
    },
    {
      path: "/dashboard",
      element: userLoggedIn ? <Dashboard /> : <Navigate to={"/"} />,
      children: [
        {
          path: "pan",
          element: <PanVerification />,
        },
        {
          path: "aadhar",
          element: <AadharVerification />,
        },
        {
          path: "gst",
          element: <GstVerification />,
        },
        {
          path: "passport",
          element: <PassportVerification />,
        },
        {
          path: "customer",
          element: <CustomerComponent />,
        },
        {
          path: "customer",
          element: <CustomerComponent />,
        },
        {
          path: ":customerID/cards",
          element: <CustomerCard />,
        },
      ],
    },
    {
      path: "/privacyPolicy",
      element: <PrivacyPolicyPage />,
    },
    {
      path: "/tos",
      element: <TermsOfService />,
    },
  ]);

  return (
    <div className="dark:bg-black w-full">
      <IntercomInitializer />
      <RouterProvider router={appRoutes} />
    </div>
  );
}

export default App;
