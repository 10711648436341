import React, { useState } from "react";
import { MdNotificationsNone } from "react-icons/md";
import { FiHelpCircle } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io5";
import { FaPlusCircle } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import Switch from "@mui/material/Switch";

const DashboardHeader = () => {
  const [isFocused, setIsFocused] = useState<Boolean>(false);

  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  return (
    <div className="flex justify-between items-center py-4 px-10  shadow-sm z-20 sticky top-0 bg-[#e8edf2]">
      <div className="w-[50%] relative ">
        <input
          type="text"
          placeholder="Search"
          className={`relative px-10 py-2 w-full bg-transparent outline-none rounded-full ${
            isFocused ? " border border-gray-300" : ""
          }`}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <span className="absolute left-3 top-3 flex items-center">
          <IoSearch />
        </span>
      </div>
      <div className="flex gap-x-6 items-center">
        <span className="cursor-pointer hover:text-purple-700">Developers</span>
        <div className="flex items-center">
          <span>Test Mode</span>
          <span>
            <Switch color="secondary" />
          </span>
        </div>
        <span className="text-2xl cursor-pointer  hover:bg-purple-300 hover:rounded-full hover:text-purple-700">
          <MdNotificationsNone />
        </span>
        <span className="text-xl cursor-pointer hover:bg-purple-300 hover:rounded-full hover:text-purple-700">
          <FiHelpCircle />
        </span>
        <span className="text-xl cursor-pointer hover:bg-purple-300 hover:rounded-full hover:text-purple-700">
          <IoSettingsOutline />
        </span>
        <span className="text-3xl cursor-pointer text-purple-600">
          <FaPlusCircle />
        </span>
      </div>
    </div>
  );
};

export default DashboardHeader;
