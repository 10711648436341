import React, {
  useState,
  createContext,
  useEffect,
  useContext,
  PropsWithChildren,
} from "react";
import { auth } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
// import { useNavigate } from "react-router-dom";
interface User {
  // Define the properties of your user object
  email: string;
  password: string;
}

interface AuthContextType {
  currentUser: User | null;
  userLoggedIn: boolean;
  isLoading: boolean;
}

const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  userLoggedIn: false,
  isLoading: true,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const unSubsCribe = onAuthStateChanged(auth, initializeUser);
    return unSubsCribe;
  }, []);

  const initializeUser = async (user: any) => {
    if (user) {
      setCurrentUser({ ...user });
      setUserLoggedIn(true);
    } else {
      setCurrentUser(null);
      setUserLoggedIn(false);
    }
    setIsLoading(false);
  };
  const value = {
    currentUser,
    userLoggedIn,
    isLoading,
  };

  return (
    <AuthContext.Provider value={value}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
};
