import React, { useState } from "react";
import "../../App.css";
import {
  MdOutlineStorefront,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  MdLogout,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { home, shortcuts, products } from "../../utils/sidebarConstants";
import { doSignOut } from "../../components/context/auth";
import { Link } from "react-router-dom";

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const [dropDownId, setDropDownId] = useState<Number | null>(0);
  const [clickedId, setclickedId] = useState<Number>(0);

  const handleDropDownId = (id: any) => {
    setDropDownId((prevDropDownId) => (prevDropDownId === id ? null : id));
    setclickedId(id);
  };

  const handleClick = (id: any) => {
    setclickedId(id);
  };

  return (
    <div className="w-[20%] h-[100vh] border-r border-gray-300 px-3 py-4 overflow-y-scroll scrollbar-hide sticky bottom-0 left-0 top-0">
      <div className="flex gap-x-3 items-center p-2 m-1 cursor-pointer">
        <span className="text-gray-500 text-lg">
          <MdOutlineStorefront />
        </span>
        <span className="font-bold">Velibo , LLC</span>
      </div>
      <div className="mt-4">
        {home?.map((d) => {
          return (
            <Link to={`${d.route}`} key={d.id}>
              <div
                className={`flex gap-x-3 items-center py-1 px-2 m-1 rounded-md cursor-pointer hover:text-purple-700 hover:font-medium hover:bg-purple-200 ${
                  clickedId === d.id
                    ? "text-purple-700 font-medium bg-purple-200"
                    : ""
                }`}
                onClick={() => handleClick(d.id)}
              >
                <div className=" hover:text-purple-600 text-gray-500 text-lg">
                  {d?.icon}
                </div>
                <div>{d?.name}</div>
              </div>
            </Link>
          );
        })}
      </div>

      <div className="mt-4">
        <span className="text-sm px-1 text-gray-400">Shortcuts</span>
        {shortcuts?.map((d) => {
          return (
            <div
              key={d.id}
              className={`flex gap-x-3 items-center py-1 px-2 m-1 rounded-md cursor-pointer hover:text-purple-600 hover:font-medium hover:bg-purple-200  ${
                clickedId === d.id
                  ? "text-purple-700 font-medium bg-purple-200"
                  : ""
              }`}
              onClick={() => handleClick(d.id)}
            >
              <div className=" hover:text-purple-600 text-gray-500 text-lg">
                {d?.icon}
              </div>
              <div>{d?.name}</div>
            </div>
          );
        })}
      </div>

      <div className="mt-4">
        <span className="text-sm px-1 text-gray-400">Products</span>
        {products?.map((d) => {
          return (
            <>
              <div
                key={d.id}
                className={` flex justify-between items-center py-1 px-2 m-1 rounded-md cursor-pointer hover:text-purple-600 hover:font-medium hover:bg-purple-200 ${
                  clickedId === d.id
                    ? "text-purple-700 font-medium bg-purple-200"
                    : ""
                }`}
                onClick={() => handleDropDownId(d.id)}
              >
                <div className="flex gap-x-3 items-center">
                  <div className=" hover:text-purple-600 text-gray-500 text-lg">
                    {d?.icon}
                  </div>
                  <div>{d?.name}</div>
                </div>

                <div>
                  <span className="text-gray-500 hover:text-purple-600">
                    {dropDownId === d.id ? (
                      <MdOutlineKeyboardArrowUp />
                    ) : (
                      <MdOutlineKeyboardArrowDown />
                    )}
                  </span>
                </div>
              </div>
              {d?.subPoints &&
                dropDownId === d.id &&
                d?.subPoints.map((ele) => {
                  return (
                    <Link to={ele.route}>
                      <div
                        key={d.id}
                        className={`flex justify-between items-center py-1 pl-10 m-1 rounded-md text-sm text-gray-600 cursor-pointer hover:text-purple-600 hover:font-medium ${
                          clickedId === ele.id
                            ? "text-purple-600 font-medium"
                            : ""
                        }`}
                        onClick={() => handleClick(ele.id)}
                      >
                        <span>{ele.name}</span>
                      </div>
                    </Link>
                  );
                })}
            </>
          );
        })}

        <div className="mt-4">
          <div className="flex gap-x-3 items-center py-1 px-2 m-1 rounded-md cursor-pointer hover:text-purple-600 hover:font-medium hover:bg-purple-200">
            <div className=" hover:text-purple-600 text-gray-500 text-lg">
              <MdLogout />
            </div>
            <button
              onClick={() => {
                doSignOut().then(() => {
                  navigate("/");
                });
              }}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
