import { FiHome } from "react-icons/fi";
import { GrTransaction } from "react-icons/gr";
import { FaRegUser, FaChartLine } from "react-icons/fa";
import { BsBoxSeam } from "react-icons/bs";
import { TbAdjustmentsHorizontal, TbReceipt } from "react-icons/tb";
import { IoWalletOutline } from "react-icons/io5";
import { IoIosMore } from "react-icons/io";
import { TiPinOutline } from "react-icons/ti";
import { FaRegClock } from "react-icons/fa6";
import { LuBarChart4 } from "react-icons/lu";

interface Item {
  name: string;
  icon: JSX.Element;
  subPoints?: subPointsItems[];
  route?: string;
  id: any;
}

interface subPointsItems {
  name: string;
  route: string;
  id: any;
}

export const home: Item[] = [
  {
    name: "Home",
    icon: <FiHome />,
    id: 1,
  },
  {
    name: "Balances",
    icon: <TbAdjustmentsHorizontal />,
    id: 2,
  },
  {
    name: "Transactions",
    icon: <GrTransaction />,
    id: 3,
  },
  {
    name: "Customers",
    icon: <FaRegUser />,
    route: "/dashboard/customer",
    id: 4,
  },
  {
    name: "Product Catalog",
    icon: <BsBoxSeam />,
    id: 5,
  },
];

export const shortcuts: Item[] = [
  {
    name: "Atlas",
    icon: <TiPinOutline />,
    id: 6,
  },
  {
    name: "Revenue recognition",
    icon: <FaRegClock />,
    id: 7,
  },
  {
    name: "Reports",
    icon: <FaRegClock />,
    id: 8,
  },
  {
    name: "Invoices",
    icon: <FaRegClock />,
    id: 9,
  },
  {
    name: "Subscriptions",
    icon: <FaRegClock />,
    id: 10,
  },
];

export const products: Item[] = [
  {
    name: "Payments",
    icon: <IoWalletOutline />,
    subPoints: [],
    id: 11,
  },
  {
    name: "Finance",
    icon: <FaChartLine />,
    subPoints: [
      {
        name: "Aadhar Verification",
        route: "/dashboard/aadhar",
        id: 12_1,
      },
      {
        name: "PAN Verification",
        route: "/dashboard/pan",
        id: 12_2,
      },
      {
        name: "GST Verification",
        route: "/dashboard/gst",
        id: 12_3,
      },
      {
        name: "Passport Verification",
        route: "/dashboard/passport",
        id: 12_4,
      },
    ],
    id: 12,
  },
  {
    name: "Billing",
    icon: <TbReceipt />,
    subPoints: [],
    id: 13,
  },
  {
    name: "Reporting",
    icon: <LuBarChart4 />,
    subPoints: [],
    id: 14,
  },
  {
    name: "More",
    icon: <IoIosMore />,
    subPoints: [],
    id: 15,
  },
];
