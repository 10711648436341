import React, { useState, useRef, FormEvent } from "react";
import google from "../../assets/google.svg";
import github from "../../assets/github.svg";
import { IoEyeOutline } from "react-icons/io5";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { Navigate } from "react-router-dom";
import {
  doSignInWithEmailAndPassword,
  doSignInWithGogle,
} from "../context/auth";
import { useAuth } from "../context/authContext";

const Form: React.FC = () => {
  const { userLoggedIn }: any = useAuth();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [ispasswordValid, setIsPasswordValid] = useState<boolean>(true);
  const [isSigningIn, setIsSigningIn] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!isSigningIn) {
      if (emailInputRef.current && passwordInputRef.current) {
        const enteredEmail = emailInputRef.current.value;
        const enteredPassword = passwordInputRef.current.value;

        const isValidEmail = /\S+@\S+\.\S+/.test(enteredEmail);
        setIsEmailValid(isValidEmail);

        const isValidPassword = enteredPassword.trim().length >= 8;
        setIsPasswordValid(isValidPassword);

        if (!isValidEmail || !isValidPassword) {
          return;
        }

        setIsSigningIn(true);
        try {
          await doSignInWithEmailAndPassword(enteredEmail, enteredPassword);
          emailInputRef.current.value = "";
          passwordInputRef.current.value = "";
        } catch (error: any) {
          console.error("Error signing in:", error);
          setError(true);
        } finally {
          setIsSigningIn(false);
        }
      }
    }
  };

  const onGoogleSignIn = (e: FormEvent) => {
    e.preventDefault();
    if (!isSigningIn) {
      setIsSigningIn(true);
      doSignInWithGogle().catch((err) => {
        setIsSigningIn(false);
        setError(true);
      });
    }
  };

  return (
    <div className=" flex flex-col sm:flex-row justify-center items-start mt-10 relative">
      {userLoggedIn && <Navigate to={"/dashboard"} replace={true} />}
      <div className=" flex justify-center flex-col px-10 py-4 pb-8 sm:pb-0">
        <span className="text-xl sm:text-2xl">
          Log in with another Provider
        </span>
        <div className="mt-8 sm:mt-10">
          <button
            className="flex gap-x-2 justify-center items-center border w-full px-4 py-2 shadow-md mb-6"
            disabled={isSigningIn}
            onClick={(e) => onGoogleSignIn(e)}
          >
            <span>
              <img src={google} alt="googleLogo" />
            </span>
            <span className=" font-semibold text-gray-600">Google</span>
          </button>
          <button className="flex gap-x-2 items-center justify-center border w-full px-4 py-2 shadow-md">
            <span>
              <img src={github} alt="googleLogo" />
            </span>
            <span className=" font-semibold text-gray-600">Github</span>
          </button>
        </div>
      </div>
      <div className="border-t-2 md:border-l-2 md:border-t-0 py-4 px-10 relative flex justify-center flex-col">
        <span className="text-xl sm:text-2xl ">Log in with Cloud Manager</span>
        <div className="mt-6 sm:mt-8 relative">
          <form onSubmit={handleSubmit}>
            <fieldset className="border border-solid border-gray-300 p-2 relative">
              <legend className="text-sm text-gray-500">Email</legend>
              <input
                type="email"
                name="email"
                required
                className="w-full outline-none bg-transparent"
                ref={emailInputRef}
              />
              {!isEmailValid && (
                <p className="text-red-500 text-xs absolute -bottom-5 sm:-bottom-4">
                  Invalid email
                </p>
              )}
            </fieldset>

            <fieldset className="border border-solid border-gray-300 p-2 mt-4 relative ">
              <legend className="text-sm text-gray-500">Password</legend>
              <input
                type={showPassword === true ? "text" : "password"}
                name="password"
                required
                className="w-full outline-none bg-transparent relative"
                ref={passwordInputRef}
              />
              <div
                className="absolute top-2 right-2 cursor-pointer "
                onClick={handleShowPassword}
              >
                {showPassword === false ? (
                  <AiOutlineEyeInvisible className="text-gray-400 text-xl" />
                ) : (
                  <IoEyeOutline className="text-gray-400 text-xl" />
                )}
              </div>
              {!ispasswordValid && (
                <p className="text-red-500 text-xs absolute -bottom-10 sm:-bottom-5">
                  Password should be minimum 8 characters long
                </p>
              )}
            </fieldset>
            <div className="flex justify-center mt-14 sm:mt-8">
              <button
                className=" px-4 py-2 rounded-md bg-slate-600 text-white"
                type="submit"
              >
                {isSigningIn ? "Logging in..." : "Login"}
              </button>
            </div>
          </form>
        </div>
      </div>
      {error && (
        <div className=" text-red-500 text-sm text-center absolute -bottom-2">
          Something went wrong, check email and password!
        </div>
      )}
    </div>
  );
};

export default Form;
