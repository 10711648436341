import React from "react";
import Header from "../components/Header";
import Form from "../components/login/form";
import { Link } from "react-router-dom";

const LoginPage: React.FC = () => {
  return (
    <>
      <div>
        <Header />
      </div>
      <div className="w-[92%] mx-auto flex pt-28 justify-center items-center flex-col">
        <h1 className="text-3xl font-bold dark:text-white">Log in to Velibo</h1>
        <div className="w-full flex justify-center ">
          <Form />
        </div>

        <p className="text-sm text-start my-8 dark:text-white">
          Don't have an account?{" "}
          <Link to={"/signup"}>
            {" "}
            <span className="text-blue-500 underline">Sign Up</span>
          </Link>
        </p>
      </div>
    </>
  );
};

export default LoginPage;
