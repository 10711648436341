import Button from "@mui/material/Button";
import React from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
const CustomerCard = () => {
  const Navigate = useNavigate();
  return (
    <div className="p-3">
      <h1 className="text-4xl font-bold flex gap-x-3 items-center">
        <span onClick={() => Navigate(-1)}>
          <IoArrowBack className="w-6 h-6 cursor-pointer" />
        </span>
        Customer Cards
      </h1>
      <div className="flex justify-end items-center">
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#9333EA",
            "&:hover": { backgroundColor: "#7b2bbf" },
          }}
        >
          Add Card
        </Button>
      </div>

      <div className="grid grid-cols-3 mt-5">
        <div className="w-[360px] h-[220px] border-2 rounded-[21px] bg-[#633EF8] relative overflow-hidden p-5 z-[0]">
          <div className="absolute w-[305px] h-[305px] rounded-[50%] bg-[#8567FF] -right-16 -top-10 z-[0]"></div>
          <div className="absolute w-[250px] h-[250px] rounded-[50%] bg-[#7856FF] -right-16 -bottom-24 z-[0]"></div>

          <p className="text-white uppercase z-[10] text-sm relative">
            Kunal Kishore
          </p>
          <p className="absolute bottom-6 left-6 text-white uppercase text-xs">
            valid till&nbsp; &nbsp; 06/2025
          </p>
          <p className="absolute bottom-5 right-8 text-white uppercase font-bold text-lg">
            Visa
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustomerCard;
