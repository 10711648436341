import { useEffect, useState } from "react";
import axios from "axios";
import { MdVerified } from "react-icons/md";
import { FaCircleExclamation } from "react-icons/fa6";
import BasicTable from "../../components/Table";

interface propsGstnRecords {
  registrationName: string;
}

interface propsGstnDetails {
  tradeNameOfBusiness: string;
}
interface propsFillingStatus {
  gstType: string;
  gstStatus: string;
  filingYear: string;
  dateOfFiling: string;
  monthOfFiling: string;
  methodOfFilling: string;
}
interface gstDetails {
  gstnRecords: propsGstnRecords[];
  filingStatus: propsFillingStatus[];
  gstnDetailed: propsGstnDetails;
}

const GstVerification = () => {
  const [data, setData] = useState<gstDetails | null>();
  // const [data, setData] = useState([] as any);
  const [gstNumber, setgstNumber] = useState<string>("");
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [submittedGstNumber, setSubmittedGstNumber] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (submittedGstNumber === "") return;

      try {
        const requestData = {
          gstin: submittedGstNumber,
        };

        const config = {
          method: "POST",
          maxBodyLength: Infinity,
          url: "https://signzy-node-proxy.onrender.com/api/v1/fetch/gstinDetailSearch",
          headers: {
            "x-api-key": process.env.REACT_APP_PROXY_API_KEY,
            "Content-Type": "application/json",
          },
          data: requestData,
        };

        const response = await axios.request(config);

        setData(response.data.result);
        console.log(data);
        setIsVerified(false);
      } catch (error: any) {
        console.error(error.message);
        setError(error.message);
        setIsVerified(false);
      } finally {
        setIsVerified(false);
      }
    };

    fetchData();
  }, [submittedGstNumber]);

  const validateGstNumber = (gst: string) => {
    const gstPattern =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    return gstPattern.test(gst);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isVerified) return;
    if (!validateGstNumber(gstNumber)) {
      setError("Invalid GST number format.");
      return;
    }
    setData(null);
    setIsVerified(true);
    setSubmittedGstNumber(gstNumber);
  };

  const handleGstNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setgstNumber(value);
    setIsVerified(false);
    if (!validateGstNumber(value)) {
      setError("Invalid GST number format.");
    } else {
      setError(null);
    }
  };

  return (
    <div className="py-8 px-10">
      <h1 className="text-3xl font-bold">Get Your GST Details</h1>
      <form className="py-6 px-20" onSubmit={handleSubmit}>
        <label className="text-lg font-semibold text-gray-500">
          GST Number :
        </label>{" "}
        <br />
        <input
          required
          type="text"
          placeholder="Enter your GST Number"
          value={gstNumber}
          onChange={handleGstNumber}
          pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
          maxLength={15}
          title="GST Number should be a 15-character alphanumeric code"
          className="bg-transparent outline-none my-2 py-2 px-4 rounded sm border-2 border-purple-200 w-[300px]"
        />
        <button
          className="bg-purple-600 py-2 px-4 text-white mx-4 rounded-md"
          // disabled={gstNumber.length === 15 ? false : true}
        >
          Search
        </button>
      </form>
      {isVerified && (
        <span className="mx-20 text-gray-600">Verifying Data...</span>
      )}
      {!isVerified && data && (
        <div className=" mx-20 bg-green-200 w-[500px] px-4 py-3 rounded-md text-green-600 flex items-center gap-x-2">
          <span className="text-xl">
            <MdVerified />
          </span>
          <span>Below are Your GST Details</span>
        </div>
      )}{" "}
      {submittedGstNumber && !isVerified && error && data=== null &&(
        <div className=" mx-20 bg-red-200 w-[500px] px-4 py-3 rounded-md text-red-600 flex items-center gap-x-2">
          <span className="text-xl">
            <FaCircleExclamation />
          </span>
          <span>Can't get Your at the moment </span>
        </div>
      )}
      {data && !isVerified && (
        <div className="mx-20 p-4 flex flex-col">
          <span className="py-1">
            Name :{" "}
            <span className="text-gray-500">
              {data?.gstnRecords[0]?.registrationName}
            </span>{" "}
          </span>

          <span className="py-1">
            Name Of Business :{" "}
            <span className="text-gray-500">
              {data?.gstnDetailed?.tradeNameOfBusiness}
            </span>{" "}
          </span>

          <BasicTable fillingStatus={data?.filingStatus || []} />
        </div>
      )}
    </div>
  );
};

export default GstVerification;
