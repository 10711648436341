import React, { useState, ChangeEvent } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import { FaPencil, FaTrash } from "react-icons/fa6";
import { Link } from "react-router-dom";

interface Customer {
  customerId?: string;
  name: string;
  email: string;
}

const CustomerComponent: React.FC = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentCustomerIndex, setCurrentCustomerIndex] = useState<
    number | null
  >(null);
  const [newCustomer, setNewCustomer] = useState<Customer>({
    name: "",
    email: "",
  });

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
    setNewCustomer({ name: "", email: "" });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewCustomer({ ...newCustomer, [name]: value });
  };

  const handleAddCustomer = () => {
    setCustomers([...customers, newCustomer]);
    setNewCustomer({ name: "", email: "" });
    handleClose();
  };

  const handleEditCustomer = () => {
    if (currentCustomerIndex !== null) {
      const updatedCustomers = customers.map((customer, index) =>
        index === currentCustomerIndex ? newCustomer : customer
      );
      setCustomers(updatedCustomers);
    }
    handleClose();
  };

  const handleEditClick = (index: number) => {
    setCurrentCustomerIndex(index);
    setNewCustomer(customers[index]);
    setIsEditing(true);
    setOpen(true);
  };

  const handleDeleteCustomer = (index: number) => {
    const updatedCustomers = customers.filter((_, i) => i !== index);
    setCustomers(updatedCustomers);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  return (
    <div className="p-3">
      <h1 className="text-4xl">Customers</h1>
      <div className="flex justify-end items-center">
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{
            backgroundColor: "#9333EA",
            "&:hover": { backgroundColor: "#7b2bbf" },
          }}
        >
          Add Customer
        </Button>
      </div>
      <TableContainer component={Paper} style={{ marginTop: 20 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No customers to show
                </TableCell>
              </TableRow>
            ) : (
              customers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((customer, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Link
                        to={`/dashboard/${
                          customer.customerId ? customer.customerId : index
                        }/cards`}
                      >
                        {customer.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Link
                        to={`/dashboard/${
                          customer.customerId ? customer.customerId : index
                        }/cards`}
                      >
                        {customer.email}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleEditClick(index)}
                      >
                        <FaPencil className="w-4 h-4 text-black" />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDeleteCustomer(index)}
                      >
                        <FaTrash className="w-4 h-4 text-black" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={customers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {isEditing ? "Edit Customer" : "Add New Customer"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            value={newCustomer.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={newCustomer.email}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={isEditing ? handleEditCustomer : handleAddCustomer}
            color="primary"
          >
            {isEditing ? "Save" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomerComponent;
