import React, { useState, useRef, FormEvent } from "react";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { Navigate } from "react-router-dom";

import { useAuth } from "../context/authContext";
import {
  doCreateUserWithEmailAndPassword,
  doSignInWithGogle,
} from "../context/auth";

const RegisterForm: React.FC = () => {
  const { userLoggedIn }: any = useAuth();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const ConfirmPasswordInputRef = useRef<HTMLInputElement>(null);

  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [ispasswordValid, setIsPasswordValid] = useState<boolean>(true);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isSamePassword, setIsSamePassword] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!isRegistering) {
      if (
        emailInputRef.current &&
        passwordInputRef.current &&
        ConfirmPasswordInputRef.current
      ) {
        const enteredEmail = emailInputRef.current.value;
        const enteredPassword = passwordInputRef.current.value;
        const enteredConfirmPassword = ConfirmPasswordInputRef.current?.value;

        const isValidEmail = /\S+@\S+\.\S+/.test(enteredEmail);
        setIsEmailValid(isValidEmail);

        const isValidPassword = enteredPassword.trim().length >= 8;
        setIsPasswordValid(isValidPassword);

        const isPasswordSame = enteredPassword === enteredConfirmPassword;
        setIsSamePassword(isPasswordSame);

        if (
          !isValidEmail ||
          !isValidPassword ||
          enteredPassword !== enteredConfirmPassword
        ) {
          return;
        }
        setIsRegistering(true);
        try {
          await doCreateUserWithEmailAndPassword(enteredEmail, enteredPassword);

          emailInputRef.current.value = "";
          passwordInputRef.current.value = "";
          ConfirmPasswordInputRef.current.value = "";
        } catch (err: any) {
          console.log("Something went wrong");
          setError(true);
        } finally {
          setIsRegistering(false);
        }
      }
    }
  };

  const onGoogleSignIn = (e: FormEvent) => {
    e.preventDefault();
    if (!isRegistering) {
      setIsRegistering(true);
      doSignInWithGogle().catch((err) => {
        setIsRegistering(false);
      });
    }
  };

  return (
    <div className="my-12 w-[90%] md:w-[400px] mx-auto">
      {userLoggedIn && <Navigate to={"/dashboard"} replace={true} />}
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col mb-6">
          <label
            htmlFor="email"
            className="dark:text-white text-sm font-medium mb-1 star"
          >
            Email
          </label>
          <div className="relative w-full">
            <input
              type="email"
              name="email"
              placeholder="Enter your email address"
              required
              className="border border-[#747373] dark:border-[#404040] py-2 px-2 bg-transparent placeholder:text-slate-400 rounded-md outline-none dark:text-white w-full"
              ref={emailInputRef}
            />
            {!isEmailValid && (
              <p className="text-red-500 text-xs absolute -bottom-4">
                Invalid email
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col mb-8">
          <label
            htmlFor="password"
            className="dark:text-white text-sm font-medium mb-1 star"
          >
            Password
          </label>
          <div className="relative w-full">
            <input
              type={showPassword === true ? "text" : "password"}
              name="password"
              placeholder="Enter password"
              required
              className="border border-[#747373] dark:border-[#404040] py-2 px-2 bg-transparent placeholder:text-slate-400 rounded-md outline-none dark:text-white w-full  pr-6"
              ref={passwordInputRef}
            />
            <div
              className="absolute top-2 right-2 cursor-pointer"
              onClick={handleShowPassword}
            >
              {showPassword === false ? (
                <EyeInvisibleOutlined className="text-gray-400" />
              ) : (
                <EyeOutlined className="text-gray-400" />
              )}
            </div>
            {!ispasswordValid && (
              <p className="text-red-500 text-xs absolute -bottom-4">
                Password should be minimum 8 characters long
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col mb-8">
          <label
            htmlFor="confirmPassword"
            className="dark:text-white text-sm font-medium mb-1 star"
          >
            Confirm Password
          </label>
          <div className="relative w-full">
            <input
              type={showConfirmPassword === true ? "text" : "password"}
              name="confirmPassword"
              placeholder="Confirm password"
              required
              className="border border-[#747373] dark:border-[#404040] py-2 px-2 bg-transparent placeholder:text-slate-400 rounded-md outline-none dark:text-white w-full  pr-6"
              ref={ConfirmPasswordInputRef}
            />
            <div
              className="absolute top-2 right-2 cursor-pointer"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword === false ? (
                <EyeInvisibleOutlined className="text-gray-400" />
              ) : (
                <EyeOutlined className="text-gray-400" />
              )}
            </div>
            {!isSamePassword && (
              <p className="text-red-500 text-xs absolute -bottom-4">
                Password not same
              </p>
            )}
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="w-full border py-2 text-white rounded-md bg-black dark:bg-white dark:text-black "
          >
            {isRegistering ? "Signing Up..." : "Sign Up"}
          </button>
        </div>
      </form>

      <div className="mt-8 flex justify-center items-center">
        <hr className=" flex-1 border-[#747373]" />
        <span className="flex-1 text-center text-slate-400 text-sm font-thin">
          or signup with
        </span>
        <hr className="flex-1 border-[#747373]" />
      </div>
      <div className="my-8">
        <button
          disabled={isRegistering}
          onClick={(e) => onGoogleSignIn(e)}
          className="border border-[#747373] dark:border-[#404040] w-full py-1 rounded flex justify-center items-center dark:text-white"
        >
          <span>
            <svg
              id="Capa_1"
              version="1.1"
              viewBox="0 0 150 150"
              xmlSpace="preserve"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              className="w-8 h-8"
            >
              <style type="text/css">
                {`
        .st0{fill:#1A73E8;}
        .st1{fill:#EA4335;}
        .st2{fill:#4285F4;}
        .st3{fill:#FBBC04;}
        .st4{fill:#34A853;}
        .st5{fill:#4CAF50;}
        .st6{fill:#1E88E5;}
        .st7{fill:#E53935;}
        .st8{fill:#C62828;}
        .st9{fill:#FBC02D;}
        .st10{fill:#1565C0;}
        .st11{fill:#2E7D32;}
        .st12{fill:#F6B704;}
        .st13{fill:#E54335;}
        .st14{fill:#4280EF;}
        .st15{fill:#34A353;}
        .st16{clip-path:url(#SVGID_2_);}
        .st17{fill:#188038;}
        .st18{opacity:0.2;fill:#FFFFFF;enable-background:new    ;}
        .st19{opacity:0.3;fill:#0D652D;enable-background:new    ;}
        .st20{clip-path:url(#SVGID_4_);}
        .st21{opacity:0.3;fill:url(#_45_shadow_1_);enable-background:new    ;}
        .st22{clip-path:url(#SVGID_6_);}
        .st23{fill:#FA7B17;}
        .st24{opacity:0.3;fill:#174EA6;enable-background:new    ;}
        .st25{opacity:0.3;fill:#A50E0E;enable-background:new    ;}
        .st26{opacity:0.3;fill:#E37400;enable-background:new    ;}
        .st27{fill:url(#Finish_mask_1_);}
        .st28{fill:#FFFFFF;}
        .st29{fill:#0C9D58;}
        .st30{opacity:0.2;fill:#004D40;enable-background:new    ;}
        .st31{opacity:0.2;fill:#3E2723;enable-background:new    ;}
        .st32{fill:#FFC107;}
        .st33{opacity:0.2;fill:#1A237E;enable-background:new    ;}
        .st34{opacity:0.2;}
        .st35{fill:#1A237E;}
        .st36{fill:url(#SVGID_7_);}
        .st37{fill:#FBBC05;}
        .st38{clip-path:url(#SVGID_9_);fill:#E53935;}
        .st39{clip-path:url(#SVGID_11_);fill:#FBC02D;}
        .st40{clip-path:url(#SVGID_13_);fill:#E53935;}
        .st41{clip-path:url(#SVGID_15_);fill:#FBC02D;}
      `}
              </style>
              <g>
                <path
                  className="st14"
                  d="M120,76.1c0-3.1-0.3-6.3-0.8-9.3H75.9v17.7h24.8c-1,5.7-4.3,10.7-9.2,13.9l14.8,11.5   C115,101.8,120,90,120,76.1L120,76.1z"
                />
                <path
                  className="st15"
                  d="M75.9,120.9c12.4,0,22.8-4.1,30.4-11.1L91.5,98.4c-4.1,2.8-9.4,4.4-15.6,4.4c-12,0-22.1-8.1-25.8-18.9   L34.9,95.6C42.7,111.1,58.5,120.9,75.9,120.9z"
                />
                <path
                  className="st12"
                  d="M50.1,83.8c-1.9-5.7-1.9-11.9,0-17.6L34.9,54.4c-6.5,13-6.5,28.3,0,41.2L50.1,83.8z"
                />
                <path
                  className="st13"
                  d="M75.9,47.3c6.5-0.1,12.9,2.4,17.6,6.9L106.6,41C98.3,33.2,87.3,29,75.9,29.1c-17.4,0-33.2,9.8-41,25.3   l15.2,11.8C53.8,55.3,63.9,47.3,75.9,47.3z"
                />
              </g>
            </svg>
          </span>{" "}
          Continue with Google
        </button>
      </div>
      {error && (
        <div className=" text-red-500 text-sm text-center">
          Something went wrong, try again!
        </div>
      )}
    </div>
  );
};

export default RegisterForm;
