import React, { useState } from "react";
import { RightOutlined } from "@ant-design/icons";

interface AccordionProps {
  title: string;
  points: Array<
    | string
    | { name: string; about?: string; icon?: JSX.Element }
    | { name: string; about?: string; icon?: JSX.Element }[]
  >;
}

const Accordion: React.FC<AccordionProps> = ({ title, points }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="text-black dark:text-white md:text-md text-sm ">
      <div className="flex flex-col">
        <div
          className="flex justify-between py-4 border-b dark:border-[#404040] cursor-pointer fold-bold hover:bg-gray-100"
          onClick={toggleAccordion}
        >
          <p>{title}</p>
          <div>
            {isOpen ? (
              <span>
                <RightOutlined className="-rotate-90 text-xs" />
              </span>
            ) : (
              <span>
                <RightOutlined className="text-xs" />
              </span>
            )}
          </div>
        </div>
      </div>
      {isOpen && (
        <>
          <div className="flex flex-col gap-2 justify-between mb-4 border-b">
            {points.map((point, index) => (
              <div key={index}>
                {typeof point === "string" ? (
                  <ul className="flex gap-x-6 justify-between flex-col">
                    <li className="text-md text-[#3D3D3A] list-disc list-inside">
                      {point}
                    </li>
                  </ul>
                ) : Array.isArray(point) ? (
                  <div
                    className={`flex gap-x-6 justify-between ${
                      typeof point[0] === "string" ? "flex-col" : "flex-row"
                    }`}
                  >
                    <div className="flex">
                      {point.map((subPoint, subIndex) => (
                        <div key={subIndex} className="flex">
                          <h3 className="text-sm">{subPoint.name}</h3>
                          <p className="md:text-md text-sm">{subPoint.about}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="flex gap-x-6 justify-between mt-4 mb-2">
                    <div className="flex gap-x-2">
                      {point?.icon && (
                        <span className="text-gray-500">{point.icon}</span>
                      )}
                      <h3 className="text-sm ">{point.name}</h3>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Accordion;
