import { useEffect, useState } from "react";
import axios from "axios";
import { MdVerified } from "react-icons/md";
import { FaCircleExclamation } from "react-icons/fa6";

interface aadharDetails {
  state: string;
  gender: string;
  ageBand: string;
  verified: string;
  mobileNumber: string;
}

const AadharVerification = () => {
  const [data, setData] = useState<aadharDetails | null>();
  const [error, setError] = useState<string | null>(null);
  const [aadharNumber, setAadharNumber] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submittedAadharNumber, setSubmittedAadharNumber] =
    useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      if (submittedAadharNumber === "") return;

      try {
        const requestData = {
          uid: submittedAadharNumber,
        };

        const config = {
          method: "POST",
          maxBodyLength: Infinity,
          url: "https://signzy-node-proxy.onrender.com/api/v1/fetch/aadharVerification",
          headers: {
            "x-api-key": process.env.REACT_APP_PROXY_API_KEY,
            "Content-Type": "application/json",
          },
          data: requestData,
        };

        const response = await axios.request(config);

        setData(response.data.result);

        setIsLoading(false);
      } catch (error: any) {
        setError(error.message);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [submittedAadharNumber]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading) return;
    setError(null);
    setData(null);
    setIsLoading(true);
    setSubmittedAadharNumber(aadharNumber.split(" ").join(""));
  };

  const formatAadharNumber = (value: string): string => {
    const digits = value.replace(/\D/g, "");

    const limitedDigits = digits.substring(0, 12);

    return limitedDigits.replace(/(\d{4})(?=\d)/g, "$1 ");
  };

  const handleAadharNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatedValue = formatAadharNumber(e.target.value);
    setAadharNumber(formatedValue);
    setIsLoading(false);
  };

  return (
    <div className="py-8 px-10">
      <h1 className="text-3xl font-bold">Verify Your Aadhar</h1>

      <form className="py-6 px-20" onSubmit={handleSubmit}>
        <label className="text-lg font-semibold text-gray-500">
          Aadhar Number :
        </label>{" "}
        <br />
        <input
          required
          type="text"
          placeholder="XXXX XXXX XXXX"
          maxLength={14}
          value={aadharNumber}
          onChange={handleAadharNumber}
          className="bg-transparent outline-none my-2 py-2 px-4 rounded sm border-2 border-purple-200 w-[300px]"
        />
        <button
          className="bg-purple-600 py-2 px-4 text-white mx-4 rounded-md"
          disabled={aadharNumber.length === 14 ? false : true}
        >
          Verify
        </button>
      </form>

      {isLoading && (
        <span className="mx-20 text-gray-600">Verifying Data...</span>
      )}

      {!isLoading && data?.verified === "true" ? (
        <div className=" mx-20 bg-green-200 w-[500px] px-4 py-3 rounded-md text-green-600 flex items-center gap-x-2">
          <span className="text-xl">
            <MdVerified />
          </span>
          <span>Aadhar is Verified and below details are as per Aadhar</span>
        </div>
      ) : !isLoading && submittedAadharNumber && data?.verified === "false" ? (
        <div className=" mx-20 bg-red-200 w-[500px] px-4 py-3 rounded-md text-red-600 flex items-center gap-x-2">
          <span className="text-xl">
            <FaCircleExclamation />
          </span>
          <span>Aadhar is not Verified</span>
        </div>
      ) : error ? (
        <div className=" mx-20 bg-red-200 w-[500px] px-4 py-3 rounded-md text-red-600 flex items-center gap-x-2">
          <span className="text-xl">
            <FaCircleExclamation />
          </span>
          <span>Can't Verify you at the moment, or contact support</span>
        </div>
      ) : null}

      {data !== null && !isLoading && data?.verified === "true" ? (
        <div className="mx-20 p-4 flex flex-col">
          <span className="py-1">
            State : <span className="text-gray-500">{data?.state}</span>{" "}
          </span>
          <span className="py-1">
            Gender: <span className="text-gray-500">{data?.gender}</span>{" "}
          </span>
          <span className="py-1">
            Age Band : <span className="text-gray-500">{data?.ageBand}</span>{" "}
          </span>
          <span className="py-1">
            Mobile Number :{" "}
            <span className="text-gray-500">{data?.mobileNumber}</span>{" "}
          </span>
        </div>
      ) : ""}
    </div>
  );
};

export default AadharVerification;
