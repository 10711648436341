import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import RegisterForm from "../components/register/register";
// import { Link } from "react-router-dom";

const SignupPage: React.FC = () => {
  return (
    <>
      <Header />

      <div className="w-[92%] mx-auto flex pt-20 justify-center items-center flex-col">
        <h1 className="text-3xl font-bold dark:text-white">
          Welcome to Velibo
        </h1>
        <div className="w-full">
          <RegisterForm />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SignupPage;
