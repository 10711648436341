import React, { useState } from "react";
import {
  DownOutlined,
  LayoutOutlined,
  GlobalOutlined,
  StockOutlined,
  DatabaseOutlined,
  JavaScriptOutlined,
  BuildOutlined,
  ApartmentOutlined,
  ToolOutlined,
  TableOutlined,
  CodeSandboxOutlined,
  ReadOutlined,
  HighlightOutlined,
} from "@ant-design/icons";
import Accordion from "./accordian";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "./context/authContext";
import { doSignOut } from "./context/auth";

import logo from "../assets/veliboLogo.png";

const mobileHEaderComponent = [
  {
    title: "Products",
    points: [
      { name: "DX Platform", icon: <LayoutOutlined /> },
      {
        name: "Infrastructure",
        icon: <GlobalOutlined />,
      },
      {
        name: "Analytics",
        icon: <StockOutlined />,
      },
      {
        name: "Storage",
        icon: <DatabaseOutlined />,
      },
      {
        name: "React.js",
        icon: <JavaScriptOutlined />,
      },
    ],
  },
  {
    title: "Solutions",
    points: [
      {
        name: "Enterprise",
        icon: <BuildOutlined />,
      },
      {
        name: "AI Apps",
        icon: <ApartmentOutlined />,
      },
      {
        name: "Platform Engineering",
        icon: <ToolOutlined />,
      },
    ],
  },
  {
    title: "Resource",
    points: [
      {
        name: "Integration",
        icon: <TableOutlined />,
      },
      {
        name: "Template",
        icon: <LayoutOutlined />,
      },
      {
        name: "Resource Center",
        icon: <CodeSandboxOutlined />,
      },
      {
        name: "Guides",
        icon: <ReadOutlined />,
      },
      {
        name: "Blog",
        icon: <HighlightOutlined />,
      },
    ],
  },
];

const Header: React.FC = () => {
  // const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [isProductsMenuOpen, setIsProductsMenuOpen] = useState<boolean>(false);
  const [isSolutionsMenuOpen, setIsSolutionsMenuOpen] =
    useState<boolean>(false);
  const [isResourcesMenuOpen, setIsResourcesMenuOpen] =
    useState<boolean>(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { userLoggedIn } = useAuth();

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleMouseEnter = (
    setStateFunction: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    const delay = setTimeout(() => {
      setStateFunction(true);
      clearTimeout(delay);
    }, 200);
  };

  const handleMouseLeave = (
    setStateFunction: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    const delay = setTimeout(() => {
      setStateFunction(false);
      clearTimeout(delay);
    }, 200);
  };

  const handleLoginClick = () => {
    navigate("/login");
  };
  const handleSignUpClick = () => {
    navigate("/signup");
  };

  return (
    <header
      className={`dark:bg-black flex justify-between fixed left-0 right-0 w-[100%] mx-auto z-20  drop-shadow-md bg-[#ffffff]`}
    >
      <div className="w-[100%] md:w-[92%] mx-auto flex justify-between py-2 relative rounded-full my-3 drop-shadow-md px-4">
        <div className="flex items-center lg:justify-normal justify-between ">
          <span
            className="w-[25px] h-[25px] mr-1 cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img src={logo} alt="logo" className="w-full h-full" />
          </span>
          <span
            className="plaster text-xl cursor-pointer"
            onClick={() => navigate("/")}
          >
            Velibo
          </span>
          <div className="flex justify-center items-center">
            {location.pathname === "/login" ||
            location.pathname === "/signup" ? (
              ""
            ) : (
              <div className="hidden lg:flex ml-8 text-gray-500">
                {/* Products Dropdown */}
                <div
                  className="relative inline-block"
                  onMouseEnter={() => handleMouseEnter(setIsProductsMenuOpen)}
                  onMouseLeave={() => handleMouseLeave(setIsProductsMenuOpen)}
                >
                  <div className="cursor-pointer hover:bg-gray-100 py-[4px] px-[12px] rounded-full hover:text-black dark:hover:bg-[#1F1F1F] dark:hover:text-white text-sm inter">
                    Products <DownOutlined className="text-xs" />
                  </div>
                  {isProductsMenuOpen && (
                    <div className="absolute bg-white dark:bg-black border dark:border-gray-700 rounded shadow-lg mt-2 py-2 px-5 w-[600px]">
                      <span className="text-gray-600 mb-12 text-sm ">
                        Velibo
                      </span>
                      <div className="flex gap-x-8 justify-between w-full">
                        <div className="hover:bg-gray-100 py-2 text-black dark:text-white flex gap-x-2 mb-4 w-[50%]">
                          <div>
                            <LayoutOutlined className="text-gray-400" />
                          </div>
                          <div>
                            <span className="text-sm">DX Platform</span>
                            <br />
                            <span className="text-gray-600 text-sm">
                              Build, deploy and interate seamlessly
                            </span>
                          </div>
                        </div>
                        <div className="hover:bg-gray-100 py-2 text-black dark:text-white flex gap-x-2 mb-4 w-[50%]">
                          <div>
                            <GlobalOutlined className="text-gray-400" />
                          </div>
                          <div>
                            <span className="text-sm">Infrastructure</span>{" "}
                            <br />
                            <span className="text-gray-600 text-sm">
                              Fast, scalable and always reliable
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-between border-b mb-5 w-full gap-x-8">
                        <div className="hover:bg-gray-100 py-2 text-black dark:text-white flex gap-x-2 mb-4 w-[50%]">
                          <div>
                            <StockOutlined className="text-gray-400" />
                          </div>
                          <div>
                            <span className="text-sm">Analytics</span> <br />
                            <span className="text-gray-600 text-sm">
                              Peak performance insigths
                            </span>
                          </div>
                        </div>

                        <div className="hover:bg-gray-100 py-2 text-black dark:text-white flex gap-x-2 mb-4 w-[50%]">
                          <div>
                            <DatabaseOutlined className="text-gray-400" />
                          </div>
                          <div>
                            <span className="text-sm">Storage</span> <br />
                            <span className="text-gray-600 text-sm">
                              Serverless storage for frontend
                            </span>
                          </div>
                        </div>
                      </div>

                      <div>
                        <span className="text-gray-600 mb-12 text-sm mt-5">
                          Open Source
                        </span>

                        <div className="flex justify-between">
                          <div className="hover:bg-gray-100 py-2 text-black dark:text-white flex gap-x-2 mb-4">
                            <div>
                              <JavaScriptOutlined className="text-gray-400" />
                            </div>
                            <div>
                              <span className="text-sm">ReactJs</span> <br />
                              <span className="text-gray-600 text-sm">
                                The native react.js platform
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="relative inline-block ml-4"
                  onMouseEnter={() => handleMouseEnter(setIsSolutionsMenuOpen)}
                  onMouseLeave={() => handleMouseLeave(setIsSolutionsMenuOpen)}
                >
                  <div className="cursor-pointer hover:bg-gray-100 py-[4px] px-[12px] rounded-full hover:text-black dark:hover:bg-[#1F1F1F] dark:hover:text-white text-sm inter">
                    Solutions <DownOutlined className="text-xs" />
                  </div>
                  {isSolutionsMenuOpen && (
                    <div className="absolute bg-white dark:bg-black border dark:border-gray-700 rounded shadow-lg mt-2 py-2 px-5 w-[600px]">
                      <span className="text-gray-600 mb-12 text-sm ">
                        Scale
                      </span>
                      <div className="flex gap-x-8 justify-between w-full border-b">
                        <div className="hover:bg-gray-100 py-2 text-black dark:text-white flex gap-x-2 mb-4 w-[50%]">
                          <div>
                            <BuildOutlined className="text-gray-400" />
                          </div>
                          <div>
                            <span className="text-sm">Enterprise</span>
                            <br />
                            <span className="text-gray-600 text-sm">
                              The complete platform for scale
                            </span>
                          </div>
                        </div>
                      </div>

                      <div>
                        <span className="text-gray-600 mb-12 text-sm mt-5">
                          Use Case
                        </span>

                        <div className="flex justify-between">
                          <div className="flex justify-between mb-5 w-full gap-x-8">
                            <div className="hover:bg-gray-100 py-2 text-black dark:text-white flex gap-x-2 mb-4 w-[50%]">
                              <div>
                                <ApartmentOutlined className="text-gray-400" />
                              </div>
                              <div>
                                <span className="text-sm">AI Apps</span> <br />
                                <span className="text-gray-600 text-sm">
                                  Powering the next breakthroughs
                                </span>
                              </div>
                            </div>

                            <div className="hover:bg-gray-100 py-2 text-black dark:text-white flex gap-x-2 mb-4 w-[50%]">
                              <div>
                                <ToolOutlined className="text-gray-400" />
                              </div>
                              <div>
                                <span className="text-sm">
                                  Platform Engineering
                                </span>{" "}
                                <br />
                                <span className="text-gray-600 text-sm">
                                  Toolkit to extend your backend
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="relative inline-block ml-4"
                  onMouseEnter={() => handleMouseEnter(setIsResourcesMenuOpen)}
                  onMouseLeave={() => handleMouseLeave(setIsResourcesMenuOpen)}
                >
                  <div className="cursor-pointer hover:bg-gray-100 py-[4px] px-[12px] rounded-full hover:text-black dark:hover:bg-[#1F1F1F] dark:hover:text-white text-sm inter">
                    Resources <DownOutlined className="text-xs" />
                  </div>
                  {isResourcesMenuOpen && (
                    <div className="absolute bg-white dark:bg-black border dark:border-gray-700 rounded shadow-lg mt-2 py-2 px-5 w-[600px]">
                      <span className="text-gray-600 mb-12 text-sm ">
                        Tools
                      </span>
                      <div className="flex gap-x-8 justify-between w-full">
                        <div className="hover:bg-gray-100 py-2 text-black dark:text-white flex gap-x-2 mb-4 w-[50%]">
                          <div>
                            <TableOutlined className="text-gray-400" />
                          </div>
                          <div>
                            <span className="text-sm">Integrations</span>
                            <br />
                            <span className="text-gray-600 text-sm">
                              Extend and automate workflows
                            </span>
                          </div>
                        </div>
                        <div className="hover:bg-gray-100 py-2 text-black dark:text-white flex gap-x-2 mb-4 w-[50%]">
                          <div>
                            <LayoutOutlined className="text-gray-400" />
                          </div>
                          <div>
                            <span className="text-sm">Templates</span> <br />
                            <span className="text-gray-600 text-sm">
                              Jumpstart with pre-build solutions
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-between border-b mb-5 w-full gap-x-8">
                        <div className="hover:bg-gray-100 py-2 text-black dark:text-white flex gap-x-2 mb-4 w-[50%]">
                          <div>
                            <CodeSandboxOutlined className="text-gray-400" />
                          </div>
                          <div>
                            <span className="text-sm">Resource Center</span>{" "}
                            <br />
                            <span className="text-gray-600 text-sm">
                              Discover today's best practices
                            </span>
                          </div>
                        </div>

                        <div className="hover:bg-gray-100 py-2 text-black dark:text-white flex gap-x-2 mb-4 w-[50%]">
                          <div>
                            <ReadOutlined className="text-gray-400" />
                          </div>
                          <div>
                            <span className="text-sm">Guides</span> <br />
                            <span className="text-gray-600 text-sm">
                              Find help quickly
                            </span>
                          </div>
                        </div>
                      </div>

                      <div>
                        <span className="text-gray-600 mb-12 text-sm ">
                          Company
                        </span>

                        <div className="flex justify-between">
                          <div className="hover:bg-gray-100 py-2 text-black dark:text-white flex gap-x-2 mb-4">
                            <div>
                              <HighlightOutlined className="text-gray-400" />
                            </div>
                            <div>
                              <span className="text-sm">Blog</span> <br />
                              <span className="text-gray-600 text-sm">
                                The latest from Velibo
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="cursor-pointer hover:bg-gray-100 py-[4px] px-[12px] rounded-full hover:text-black dark:hover:bg-[#1F1F1F] dark:hover:text-white text-sm mx-8 inter">
                  Docs
                </div>
                <div className="cursor-pointer hover:bg-gray-100 py-[4px] px-[12px] rounded-full hover:text-black dark:hover:bg-[#1F1F1F] dark:hover:text-white text-sm inter">
                  Pricing
                </div>
              </div>
            )}
          </div>
          <div
            className={`flex-col rounded-full px-[5px] py-2 border cursor-pointer z-20 absolute right-5 ${
              location.pathname === "/login" || location.pathname === "/signup"
                ? "hidden"
                : "lg:hidden block flex"
            }`}
            onClick={handleMobileMenuToggle}
          >
            <div className="border-b-2 border-slate-500 w-[18px] mb-[6px]"></div>
            <div className="border-b-2 border-slate-500 w-[18px]"></div>
          </div>
        </div>

        <div className="flex items-center gap-x-3">
          <span className="text-sm lg:block hidden">Contact</span>
          <div
            className={`  gap-x-3 items-center ${
              location.pathname === "/login" || location.pathname === "/signup"
                ? "flex"
                : "hidden lg:flex"
            }`}
          >
            {!userLoggedIn && (
              <button
                className="text-sm  bg-none dark:bg-transparent dark:text-white px-3 py-[6px] rounded-md border border-[#747373] font-[500] hover:opacity-65"
                onClick={
                  location.pathname === "/login"
                    ? handleSignUpClick
                    : handleLoginClick
                }
              >
                {location.pathname === "/login" ? "Sign Up" : "Login"}
              </button>
            )}
            {!userLoggedIn && (
              <button
                className={`text-sm  bg-black dark:bg-white dark:text-black px-[18px] py-[6px] rounded-md font-[500] text-white hover:opacity-80 ${
                  location.pathname === "/login" ||
                  location.pathname === "/signup"
                    ? "hidden"
                    : ""
                }`}
                onClick={handleSignUpClick}
              >
                Sign Up
              </button>
            )}

            {userLoggedIn && (
              <button
                className="text-sm  bg-white dark:bg-transparent dark:text-white px-3 py-[6px] rounded-md border font-[500] hover:bg-gray-100"
                onClick={() => {
                  doSignOut().then(() => {
                    navigate("/");
                  });
                }}
              >
                Logout
              </button>
            )}
          </div>
        </div>
      </div>

      {isMobileMenuOpen && (
        <div className="lg:hidden  absolute top-[66px] right-0 w-full bg-white dark:bg-black shadow-lg z-40 ">
          <div className="flex flex-col ">
            <div className="py-2 px-4 dark:border-gray-700 w-full flex flex-col justify-between items-center gap-y-2">
              <button
                className="text-sm bg-white dark:bg-transparent dark:text-white px-3 py-[6px] w-full rounded-md border font-[500] hover:bg-gray-100"
                onClick={handleLoginClick}
              >
                Log In
              </button>
              <button
                className="text-sm bg-black dark:bg-white dark:text-black px-[18px] py-[6px] rounded-md font-[500] text-white hover:opacity-80 w-full"
                onClick={handleSignUpClick}
              >
                Sign Up
              </button>
            </div>
            <div className="py-2 px-4 dark:text-white">
              {/* Mobile Menu Items */}
              {/* <div className="hover:bg-gray-100 py-2 px-4 cursor-pointer">
                Products
              </div> */}
              {/* <div className="hover:bg-gray-100 py-2 px-4 cursor-pointer">
                Solutions
              </div>
              <div className="hover:bg-gray-100 py-2 px-4 cursor-pointer">
                Resources
              </div> */}
              {mobileHEaderComponent.map((item, index) => (
                <Accordion
                  key={index}
                  title={item.title}
                  points={item.points}
                />
              ))}
              <div className="hover:bg-gray-100 text-sm py-2 cursor-pointer">
                Docs
              </div>
              <div className="hover:bg-gray-100 text-sm py-2 cursor-pointer">
                Pricing
              </div>
              <div className="hover:bg-gray-100 text-sm py-2 cursor-pointer">
                Contact
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
