import { useEffect, useState } from "react";
import axios from "axios";
import { MdVerified } from "react-icons/md";
import { FaCircleExclamation } from "react-icons/fa6";

interface panDetails {
  title: string;
  name: string;
  panStatus: boolean;
  typeOfHolder: string;
  isValid: boolean;
}

const PanVerification = () => {
  const [data, setData] = useState<panDetails | null>();
  // const [data, setData] = useState([] as any);
  const [panNumber, setPanNumber] = useState<string>("");
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [submittedPanNumber, setSubmittedPanNumber] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      if (submittedPanNumber === "") return;

      try {
        const requestData = {
          number: submittedPanNumber,
        };

        const config = {
          method: "POST",
          maxBodyLength: Infinity,
          url: "https://signzy-node-proxy.onrender.com/api/v1/fetch/pan",
          headers: {
            "x-api-key": process.env.REACT_APP_PROXY_API_KEY,
            "Content-Type": "application/json",
          },
          data: requestData,
        };

        const response = await axios.request(config);

        setData(response.data.result);
        setIsVerified(false);
      } catch (error: any) {
        console.error(error.message);
        setIsVerified(false);
      } finally {
        setIsVerified(false);
      }
    };

    fetchData();
  }, [submittedPanNumber]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isVerified) return;
    setData(null);
    setIsVerified(true);
    setSubmittedPanNumber(panNumber);
  };

  const handlePanNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPanNumber(e.target.value);
    setIsVerified(false);
    console.log("clicked");
  };

  return (
    <div className="py-8 px-10">
      <h1 className="text-3xl font-bold">Verify Your PAN</h1>

      <form className="py-6 px-20" onSubmit={handleSubmit}>
        <label className="text-lg font-semibold text-gray-500">
          PAN Number :
        </label>{" "}
        <br />
        <input
          required
          type="text"
          placeholder="Enter your PAN Number"
          value={panNumber}
          onChange={handlePanNumber}
          className="bg-transparent outline-none my-2 py-2 px-4 rounded sm border-2 border-purple-200 w-[300px]"
        />
        <button
          className="bg-purple-600 py-2 px-4 text-white mx-4 rounded-md"
          disabled={isVerified ? true : false}
        >
          Verify
        </button>
      </form>

      {isVerified && (
        <span className="mx-20 text-gray-600">Verifying Data...</span>
      )}

      {!isVerified && data?.isValid ? (
        <div className=" mx-20 bg-green-200 w-[500px] px-4 py-3 rounded-md text-green-600 flex items-center gap-x-2">
          <span className="text-xl">
            <MdVerified />
          </span>
          <span>PAN is Verified and below details are as per PAN</span>
        </div>
      ) : (
        submittedPanNumber &&
        !isVerified && (
          <div className=" mx-20 bg-red-200 w-[500px] px-4 py-3 rounded-md text-red-600 flex items-center gap-x-2">
            <span className="text-xl">
              <FaCircleExclamation />
            </span>
            <span>PAN is not Verified</span>
          </div>
        )
      )}

      {data !== null && !isVerified && data?.isValid && (
        <div className="mx-20 p-4 flex flex-col">
          <span className="py-1">
            Name :{" "}
            <span className="text-gray-500">
              {data?.title} {data?.name}
            </span>{" "}
          </span>
          <span className="py-1">
            PAN Status :{" "}
            <span className="text-gray-500">{data?.panStatus}</span>{" "}
          </span>
          <span className="py-1">
            Type of Holder :{" "}
            <span className="text-gray-500">{data?.typeOfHolder}</span>{" "}
          </span>
        </div>
      )}
    </div>
  );
};

export default PanVerification;
