import { useState } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import heroImg from "../assets/heroSeciton.png";
import adhar from "../assets/adhar.jpg";
import passport from "../assets/passport.png";
import panCard from "../assets/pan-card.webp";
import gst from "../assets/gst.png";
import digi from "../assets/digilocker.jpg";

import { useNavigate } from "react-router-dom";

const MainContainer: React.FC = () => {
  const verifications = [
    "Aadhar Verification",
    "Passport Verification",
    "Pan Verification",
    "GST Verification",
    "Digilocker Verification",
  ];

  const [selectedVerification, setSelectedVerification] = useState(
    "Aadhar Verification"
  );

  const navigate = useNavigate();

  const getImage = () => {
    switch (selectedVerification) {
      case "Aadhar Verification":
        return {
          img: adhar,
          id: 0,
          title:
            "Authenticate identities with the unique Aadhaar number issued by the Government of India.",
        };
      case "Passport Verification":
        return {
          img: passport,
          id: 1,
          title:
            "Verify passport details swiftly to confirm the authenticity of international travel documents.",
        };
      case "Pan Verification":
        return {
          img: panCard,
          id: 2,
          title:
            "Validate Permanent Account Number (PAN) to ensure accurate financial transactions.",
        };
      case "GST Verification":
        return {
          img: gst,
          id: 3,
          title:
            "Verify Goods and Services Tax (GST) numbers to confirm the legitimacy of businesses.",
        };
      case "Digilocker Verification":
        return {
          img: digi,
          id: 4,
          title:
            "Access and verify documents stored in DigiLocker, India’s secure cloud-based document storage service.",
        };
      default:
        return {
          img: adhar,
          id: 0,
          title:
            "Authenticate identities with the unique Aadhaar number issued by the Government of India.",
        };
    }
  };

  const { img, title } = getImage();

  return (
    <>
      <div className="w-[90%] mx-auto dark:bg-black pt-[100px]"></div>

      <section className="w-[90%] flex py-3 mx-auto mb-20 mt-8">
        <div className="md:w-[50%] w-full">
          <div className=" mb-8 mx-8 mt-0">
            <span className="inter text-xs font-semibold">
              {" "}
              <span className="text-6xl">.</span> Say Hello to
            </span>
          </div>
          <div className="flex flex-col mx-auto w-[80%]">
            <p className="poller text-5xl mb-2">Velibo.</p>
            <p className="poller text-5xl mb-2">Cloud financial</p>
            <p className="praise text-5xl mb-2">Wizardry</p>
          </div>

          <div className="mx-auto w-[80%]">
            <p className="text-sm text-[#888888]">
              Velibo simplifies multi-cloud spending analysis for efficient{" "}
              <br /> optimization and innovation..
            </p>

            <div className="py-8">
              <button
                className={`text-sm  bg-black dark:bg-white dark:text-black px-[18px] py-[10px] rounded-lg font-[500] text-white hover:opacity-80 inter`}
              >
                Book Demo{" "}
                <span>
                  <ArrowRightOutlined className="font-semibold ml-2" />
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="w-[50%] md:block hidden">
          <div
            className="lg:w-[500px] md:w-[400px] mx-auto bg-[#D1DBE5] h-full -rotate-[17deg] relative"
            style={{ borderRadius: "400px 250px 503px 500px" }}
          >
            <div className="absolute w-full h-full flex justify-center items-center">
              <img
                src={heroImg}
                alt="heroImg"
                className="w-full h-full -top-8 relative"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="w-[90%] flex py-3 mx-auto mt-20 mb-28">
        <div className="w-full border-t-[#747373] border py-6 flex">
          <div className="mx-5">
            <p className="font-semibold">
              Trusted <br />
              by leading companies
            </p>
          </div>

          <div className="flex justify-between items-center w-full flex-wrap md:gap-y-0 gap-y-4">
            <div>
              <SlackOutlined className="text-[45px] lg:text-[65px] text-[#747373]" />
            </div>
            <div>
              <AmazonOutlined className="text-[45px] lg:text-[65px] text-[#747373]" />
            </div>
            <div>
              <DropboxOutlined className="text-[45px] lg:text-[65px] text-[#747373]" />
            </div>
            <div>
              <TwitterOutlined className="text-[45px] lg:text-[65px] text-[#747373]" />
            </div>
            <div>
              <DiscordOutlined className="text-[45px] lg:text-[65px] text-[#747373]" />
            </div>
          </div>
        </div>
      </section> */}

      <section className="w-[90%] flex py-3 mx-auto mt-20 mb-28">
        <p className="inter italic text-xl md:text-4xl">
          {" "}
          &nbsp; &nbsp; &nbsp; Switching your cloud cost platform? Streamline
          with Velibo. Our Innovative platform, equipped with industries-
          leading features..{" "}
        </p>
      </section>

      <section className=" flex justify-evenly items-start px-6 ">
        <div className=" hidden lg:block">
          <div
            className=" bg-[#D1DBE5] w-[600px] h-[400px]"
            style={{ borderRadius: "300px 0 300px 0" }}
          ></div>
        </div>
        <div className="lg:w-[50%] w-full py-8">
          <div className="px-8">
            <p className="text-3xl font-semibold inter mb-6">
              Velibo innovatively visualizes and simplifies understanding of
              your cloud bills and variable spending.
            </p>

            <p className="text-sm text-[#888888] mb-6">
              Unlock limitless scalability and unparalleled performance with
              Vesto Big, partnering in forecasting and strategic spending with
              global enterprises.
            </p>

            <div>
              <button
                className={`text-sm  bg-black dark:bg-white dark:text-black px-[18px] py-[12px] rounded-xl font-[500] text-white hover:opacity-80`}
              >
                Find out more{" "}
                <span>
                  <ArrowRightOutlined className="font-semibold ml-2" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="w-[90%] flex py-3 mx-auto mt-20 mb-28">
        <div
          className="w-full bg-[#D1DBE5] min-h-[650px] mx-auto relative overflow-hidden"
          style={{ borderRadius: "50px" }}
        >
          <div className="py-8">
            <h1 className="text-2xl md:text-5xl text-center inter font-semibold">
              Revolutionizing FinOps
            </h1>
          </div>

          <div className="bg-white w-[95%] md:w-[80%] mx-auto h-full rounded-[50px] flex flex-col justify-around p-20">
            <div className="flex justify-around w-full gap-x-4 flex-col lg:flex-row gap-y-4">
              <div className=" lg:w-[350px] lg:h-[250px] bg-[#ADD9FE] rounded-3xl overflow-hidden border relative">
                <div className="absolute inset-0 bg-white blur-sm mt-10"></div>
                <div className="relative p-4 mt-8 flex flex-col justify-around h-full">
                  <p className="p-4 text-xl font-semibold inter">
                    Optimize Rates and <br /> Rightsize Resources
                  </p>

                  <p className="text-sm mb-4 text-[#B0AFAF] p-4">
                    Streamline costs and resource allocation <br /> for
                    efficiency.
                  </p>
                </div>
              </div>
              <div className="lg:w-[350px] lg:h-[250px] bg-[#F9B2FF] rounded-3xl overflow-hidden border relative">
                <div className="absolute inset-0 bg-white blur-sm mt-10"></div>
                <div className="relative  p-4 mt-8 flex flex-col justify-around h-full">
                  <p className="p-4 text-xl font-semibold inter">
                    Utilize AI-Powered,
                    <br /> Customizable Insights
                  </p>

                  <p className="text-sm mb-4 text-[#B0AFAF] p-4">
                    Lavarage smart insights to inform <br /> strategic decision-
                    making.
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full flex flex-around items-center lg:flex-row flex-col">
              <p className="w-full h-full lg:w-[80%] text-xs md:text-sm lg:text-lg p-4">
                Cloud cost comprehension is intricate. Taking action to
                visualize, optimize, and institutionalize that understanding is
                even more intricate.
              </p>
              <div className="w-full h-full lg:w-[20%]  flex justify-center items-center lg:pb-0 pb-6 ">
                <button
                  className={`text-xs md:text-sm bg-black dark:bg-white dark:text-black px-[18px]  py-[12px] rounded-2xl font-[500] text-white hover:opacity-80`}
                  onClick={() => navigate("/login")}
                >
                  Find out more{" "}
                  <span>
                    <ArrowRightOutlined className="font-semibold ml-2 text-[10px] md:text-sm" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-[90%] py-3 mx-auto mt-20 mb-28">
        <div className="py-8 px-8">
          <h1 className="text-lg md:text-3xl inter font-semibold">
            <span className="text-6xl">.</span>Verification Services
          </h1>
          <p className="mt-4 italic text-sm">
            Our one-stop solution for all your essential verification needs. We
            provide seamless and secure verification services to ensure the
            authenticity and accuracy of your customer's documents.
          </p>
        </div>

        <div className="flex md:flex-row flex-col">
          <div className="w-full md:w-[50%] md:mb-0 mb-5">
            <div
              className={`w-full h-[400px] md:h-full bg-[#D1DBE5] rounded-3xl p-8 shape drop-shadow-lg overflow-hidden relative flex justify-center `}
            >
              <img
                src={img}
                alt="verification"
                className={`w-full md:w-1/2 h-full object-contain absloute`}
              />
            </div>
          </div>
          <div className="flex justify-center w-full md:w-[50%]">
            <div className="w-full md:w-[60%] flex flex-row md:flex-col justify-between py-12 flex-wrap">
              {verifications.map((verification, index) => (
                <p
                  key={index}
                  onClick={() => setSelectedVerification(verification)}
                  className={`text-xs md:text-2xl font-bold inter p-4 cursor-pointer ${
                    selectedVerification === verification
                      ? ""
                      : "text-[#B0AFAF]"
                  }`}
                >
                  {verification}
                </p>
              ))}
            </div>
          </div>
        </div>

        <div className="md:w-full flex flex-around items-center flex-col md:flex-row">
          <p className="w-[80%] md:text-xl text-md lg:text-3xl p-4 px-8 md:mt-8">
            {title}
          </p>

          <button
            className={`text-sm  bg-black dark:bg-white dark:text-black px-[18px] py-[12px] rounded-xl font-[500] text-white hover:opacity-80 md:mt-12`}
            onClick={() => navigate("/login")}
          >
            Find out more{" "}
            <span>
              <ArrowRightOutlined className="font-semibold ml-2" />
            </span>
          </button>
        </div>
      </section>

      <section className="w-[90%] flex py-3 mx-auto mt-20 mb-28 flex-col">
        <div className=" mb-4 mx-8 mt-0">
          <span className="inter text-2xl font-semibold">
            {" "}
            <span className="text-6xl">.</span> Validate Your Cloud Spend
          </span>
        </div>

        <div className="w-full flex md:px-8 justify-around flex-col md:flex-row">
          <div className="w-full md:w-[40%] flex flex-row md:flex-col justify-between py-12 flex-wrap">
            <p className="text-xs md:text-4xl font-bold inter p-4 cursor-pointer">
              Engineering
            </p>
            <p className="text-xs md:text-4xl font-bold inter p-4 text-[#B0AFAF] cursor-pointer">
              FinOps
            </p>
            <p className="text-xs md:text-4xl font-bold inter p-4 text-[#B0AFAF] cursor-pointer">
              Finance
            </p>
            <p className="text-xs md:text-4xl font-bold inter p-4 text-[#B0AFAF] cursor-pointer">
              Partners
            </p>
          </div>

          <div className="w-full md:w-[50%] md:mb-0 mb-5">
            <div className="w-full h-[300px] md:h-full bg-[#D1DBE5] rounded-3xl p-8"></div>
          </div>
        </div>

        <div className="px-8">
          <p className="font-semibold inter">Engineering</p>
        </div>

        <div className="w-full flex flex-around items-center flex-col md:flex-row ">
          <p className="w-[80%] md:text-xl text-md lg:text-3xl p-4 px-8">
            Focus on building while Velibo identifies optimization opportunities
          </p>

          <button
            className={`text-sm  bg-black dark:bg-white dark:text-black px-[18px] py-[12px] rounded-xl font-[500] text-white hover:opacity-80`}
          >
            Find out more{" "}
            <span>
              <ArrowRightOutlined className="font-semibold ml-2" />
            </span>
          </button>
        </div>
      </section>

      <section className="w-[90%] flex py-3 mx-auto mt-20 mb-28">
        <div className="w-full relative">
          <p className="italianno text-[9rem] md:text-[12rem] lg:text-[15rem] leading-tight">
            Ready <br /> &nbsp; to try Velibo?
          </p>
          <p className="absolute right-0 md:top-20 md:p-8 md:w-[40%] text-xs md:text-lg">
            Empower your company’s growth and innovation by selecting the
            optimal solution for your engineering, finance, and FinOps teams.
          </p>
        </div>
      </section>
    </>
  );
};

export default MainContainer;
